<template>
    <div id="subTopNav">
        <router-link v-if="pageTitle===this.$t('menu.13')" class="btn setting" :to="`/${$i18n.locale}/dashboard`"><i class="fas fa-chevron-left"></i></router-link>
        <router-link v-else-if="prevDashboard===true" class="btn setting" :to="`/${$i18n.locale}/dashboard`"><i class="fas fa-chevron-left"></i></router-link>
        <router-link v-else-if="prevPType===true" class="btn setting" :to="`/${$i18n.locale}/page/menu`"><i class="fas fa-chevron-left"></i></router-link>
        <button v-else class="btn setting" @click="$router.go(-1)"><i class="far fa-chevron-left"></i></button>
        <h3 class="subTit mb-0">{{pageTitle}}</h3>
        <button class="btn" @click="logout" v-if="pageTitle===this.$t('menu.13')"><i class="fas fa-power-off main_txt"></i></button>
        <button class="btn transparent" v-else><i class="fas fa-chevron-right"></i></button>
    </div>
</template>

<script>
// prevP: false => $router.go(-1) === history.back() === 뒤로가기
// prevP: true => /${$i18n.locale}/page/menu = 메뉴로 이동 = 각 컴포넌트에 backPage commit 생략 시 true로 처리됨

export default {
    data(){
        return{
        }
    },
    computed:{
        pageTitle(){
            
            return this.$store.state.headerTitle
        },
        prevPType(){
            return this.$store.state.prevPage
        },
        prevDashboard(){
            return this.$store.state.pageDashboard
        }
    },
    methods: {          
        logout() {            
            this.$confirm(this.$t("signout.2")).then(() => {                
                this.$store.dispatch('SETLOGOUT').then(()=>{
                    this.$router.push({name:'Signin'});
                });
            }).catch(() => {})
        },
    },
}
</script>

<style scoped>
/* topnav 공통 */

.setting{
    outline: none;
    color: #4B67F9;
    font-size: 14px;
}
.setting:focus{
    box-shadow: none;
}

/* 공통 끝 */
#subTopNav{
    /* position: relative; */
    position: fixed;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5em;
    box-sizing: border-box;
    max-width: 1024px;
}
.subTit{
    font-size: 17px;
    font-weight: 400;
    color: #4B67F9;
}
.transparent{
    opacity: 0;
    cursor: none;
}
</style>