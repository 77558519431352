<template>
    <div class="wrap"> 
        <TopTitle />
        <!-- <transition> -->
            <router-view></router-view>
        <!-- </transition> -->
    </div>
</template>

<script>
import TopTitle from '@/components/common/TopTitle.vue'
export default {
    name: 'top-title',
    components:{
        TopTitle
    },
    mounted() {
        const login = this.$store.state.login
        
        if(login ==false){
        this.$router.push({name:'Signin'}); 
        }
    },
}
</script>
<style>
</style>