<template>
    <div class="container">
        <ul class="menuGroup">
            
            <li class="depth">
                <h5>{{$t("menu.20")}}</h5>
                <ul class="w-100">
                    <!-- <li>
                        <router-link :to="`/${$i18n.locale}/page/package`"><span>{{$t("menu.21")}}</span>
                        <i class="fas fa-chevron-right"></i></router-link>
                    </li> -->
                    <li>
                        <router-link :to="`/${$i18n.locale}/page/transaction-detail`"><span>{{$t("menu.9")}}</span>
                        <i class="fas fa-chevron-right"></i></router-link>
                    </li>
                    
                    <!-- <li>
                        <router-link :to="`/${$i18n.locale}/page/trade`"><span>{{$t("security.5")}}</span>
                        <i class="fas fa-chevron-right"></i></router-link>
                    </li> -->
                </ul>
            </li>
            <li class="depth">
                <h5>MY</h5>
                <ul class="w-100">
                    <li>
                        <router-link :to="`/${$i18n.locale}/page/myinfo`"><span>{{$t("menu.3")}}</span>
                        <i class="fas fa-chevron-right"></i></router-link>
                    </li>
                    <!-- <li>
                        <router-link :to="`/${$i18n.locale}/page/kyc`"><span>KYC {{$t("menu.17")}}</span>
                        <i class="fas fa-chevron-right"></i></router-link>
                    </li> -->
                    <li>
                        <router-link :to="`/${$i18n.locale}/page/mywallet`"><span>{{$t("menu.18")}}</span>
                        <i class="fas fa-chevron-right"></i></router-link>
                    </li>
                    <li v-if="social == ''">
                        <router-link :to="`/${$i18n.locale}/page/chg-login-pw`"><span>{{$t("menu.4")}}</span>
                        <i class="fas fa-chevron-right"></i></router-link>
                    </li>
                    <li>
                        <router-link :to="`/${$i18n.locale}/page/chg-transaction-pw`"><span>{{$t("menu.5")}}</span>
                        <i class="fas fa-chevron-right"></i></router-link>
                    </li>
                    <!-- <li>
                        <router-link :to="`/${$i18n.locale}/page/chk-private-key`"><span>{{$t('change.38')}} Private Key</span>
                        <i class="fas fa-chevron-right"></i></router-link>
                    </li> -->
                    <!-- <li>
                        <router-link :to="`/${$i18n.locale}/page/wallet-restore`"><span>지갑 복구</span>
                        <i class="fas fa-chevron-right"></i></router-link>
                    </li> -->
                </ul>
            </li>            
            <li class="depth">
                <h5>{{$t("menu.19")}}</h5>
                <ul class="w-100">
                    <li>
                        <router-link :to="`/${$i18n.locale}/page/coin`"><span>{{$t("menu.22")}}</span>
                            <i class="fas fa-chevron-right"></i>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="`/${$i18n.locale}/page/security`"><span>{{$t("menu.7")}}</span>
                            <i class="fas fa-chevron-right"></i>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="`/${$i18n.locale}/page/language`"><span>{{$t("menu.8")}}</span>
                            <div>
                                <span class="mr-2">{{this.$store.state.lang}}</span>
                                <i class="fas fa-chevron-right"></i>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </li>
            <li class="depth">
                <h5>{{$t("menu.16")}}</h5>
                <ul class="w-100">
                    <li>
                        <router-link :to="`/${$i18n.locale}/page/notice-list`"><span>{{$t("menu.1")}}</span><i class="fas fa-chevron-right"></i></router-link>
                    </li>
                    <li>
                        <router-link :to="`/${$i18n.locale}/page/faq-list`"><span>FAQ</span><i class="fas fa-chevron-right"></i></router-link>
                    </li>
                    <li>
                        <router-link :to="`/${$i18n.locale}/page/inquiry-list`"><span>{{$t("menu.2")}}</span>
                        <i class="fas fa-chevron-right"></i>
                        </router-link>
                    </li>
                    <!-- <li>
                        <a target="_blank"  href="/assets/paper/evol_user_guide.pdf" v-if="$i18n.locale == 'ko'">
                            <span>{{$t("menu.15")}}</span>
                            <i class="fas fa-chevron-right"></i>
                        </a>
                        <a target="_blank"  href="/assets/paper/evol_user_guide.pdf" v-else>
                            <span>{{$t("menu.15")}}</span>
                            <i class="fas fa-chevron-right"></i>
                        </a>
                    </li> -->
                </ul>
            </li>
            
        </ul>
    </div>
</template>

<script>

export default {
    data(){
        return{
            title:this.$t("menu.13"),
            prevP: false,
            pageD: false,
            social: this.$store.state.social
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP);
        this.$store.commit("backDashboard",this.pageD);
    },
}
</script>

<style scoped>
/* topnav 공통 */

button.setting{
    padding: 0;
    font-size: 1.2em;
    outline: none;
    color: #d7d7d7;
}
button.setting:focus{
    box-shadow: none;
}

/* 공통 끝 */

.menuGroup>li{
    background-color: #fff;
    margin-bottom: 1.5em;
    border-radius: 6px;
    /* box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14); */
}
.menuGroup>li:last-child{
    margin-bottom: 0px
}
.menuGroup>li>a{
    display: flex;
    justify-content: space-between;
}
.menuGroup>li.depth{
    padding: 0;
}
.menuGroup>li.depth li>a{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menuGroup>li.depth li>a{
    padding: 1em 1em 0;
}
.menuGroup>li.depth li:last-of-type>a{
    padding-bottom: 1em;
}
.menuGroup>li.depth h5{
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-right: 15px;
    margin-left: 15px;
    font-weight: bold;
    font-size: 18px;
}
.menuGroup li>a{
    color: #333;
    /* font-size: 14px; */
    font-size: 1rem;
    font-weight: 500;
    padding: 1em;
}
.menuGroup li>a>i, .menuGroup li>a>div>i{
    color: rgb(200, 200, 200);
    font-size: 14px;
}

.container{
    min-height: calc(100% - 50px);
    /* background-color: #fff; */
    /* border-radius: 25px 25px 0 0; */
    padding-top: 35px;
    padding-bottom: 10px;
}
</style>